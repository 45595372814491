export const QUICKSIGHT_PRICING_MODE = {
  SESSION: "session",
  PREPAID: "prepaid",
};

export const CHANNELS = {
  CUSTOM: "custom",
  EMAIL: "email",
  SMS: "sms",
  VOICE: "voice",
  AGENT_TO_AGENT: "on_net",
  TASK: "task",
};
export const DEFAULT_IMAGE_FOLDER = "connectpath";
export const LATEST_STACK_VERSION = 1;
export const MEDIA_STREAM_TYPES = {
  AUDIO: "AUDIO",
  VIDEO: "VIDEO",
};
export const PARTNERS = {
  DIGITAL_ISLAND: "digitalisland",
  CDW: "cdw",
};
export const WEBHOOKS_PROVIDERS = {
  TWILIO: "twilio",
  OFFICE365: "office365",
  GOOGLE_WORKSPACE: "googleWorkSpace",
};

export const USERS_INTEGRATIONS_LIMIT = 10;
export const USERS_DEFAULT_LIMIT = 20;

export const IDLE = {
  MINIMUM_MINUTES: 1,
  MAXIMUM_MINUTES: 30,
};
export const AGENT_STATUSES = {
  NOT_ROUTABLE: "not_routable",
  AVAILABLE: "Available",
  OFFLINE: "Offline",
  BUSY: "Busy",
  PENDING_BUSY: "PendingBusy",
  CALLING_CUSTOMER: "CallingCustomer",
  AFTER_CONTACT_WORK: "AfterContactWork",
  AFTER_CALL_WORK: "AfterCallWork",
  ERROR: "error",
  CAP_ERROR: "Error",
  MISSED_CALL_AGENT: "MissedCallAgent",
  FAILED_CONNECT_AGENT: "FailedConnectAgent",
  REJECTED: "rejected",
  CAP_REJECTED: "Rejected",
  DEFAULT: "default",
  ACW: "ACW",
  MISSED_CALL: "Missed Call",
};
export const IDLE_UPDATE_RESTRICTED_STATUSES = [
  AGENT_STATUSES.PENDING_BUSY,
  AGENT_STATUSES.BUSY,
  AGENT_STATUSES.CALLING_CUSTOMER,
];
export const SECURITY_PROFILES = {
  DEXTR_ADMIN: "Dextr Admin",
  DEXTR_AGENT: "Dextr Agent",
  INSTANCE_ADMIN: "Instance Admin",
};

export const VIDEO_CALL_CHIME_TRANSCRIPTION_CONFIGURATION_SPECIALTY = [
  "PRIMARYCARE",
  "CARDIOLOGY",
  "NEUROLOGY",
  "ONCOLOGY",
  "RADIOLOGY",
  "UROLOGY",
];

export const VIDEO_CALL_CHIME_TRANSCRIPTION_CONFIGURATION_TYPE = ["CONVERSATION", "DICTATION"];

export const ID_PROVIDER_DEFAULT_ALPHABET = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz-";
export const ID_PROVIDER_DEFAULT_SIZE = 10;

export const CHIME_VIDEO_CALL_ID = "1fe97c08-d696-4d0e-b1c1-2eaf0883b75c";

export const PRESIGNED_S3_METHODS = {
  PUT: "PUT",
  GET: "GET",
  ALL: "ALL",
};

export const LIST_INTEGRATIONS = {
  GOOGLE_AUTH: "GOOGLE_AUTH",
};

export const INTEGRATION_PROPERTIES = {
  GOOGLE_AUTH: {
    TYPE: "googleAuth",
    PROVIDER: "Google",
    TYPENAME: "GoogleAuthIntegrationType",
  },
};
export const STEP_MACHINE_EVENT_NAMES = {
  SUCCESS: "CUSTOM_STACK_DEPLOYED",
  FAILURE: "CUSTOM_STACK_DEPLOY_FAILED",
};

export const QUICKSIGHT_DEFINITION_ID = "47d6588c-23c5-48cc-ab52-7fc4f038cac5";

export const DISASTER_RECOVERY_DEFINITION_ID = "565326ac-7e3f-4da6-9f25-c544d7d8b1fa";

export const REPLICATE_CROSS_REGION_RESOURCES_ID = "1cda4a63-3e86-439a-af7b-b71a5a7b837f";

export const TASK_TEMPLATE = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
};

export const TASK_TEMPLATE_FIELDS = {
  STRING: "STRING",
  NUMBER: "NUMBER",
  DATE: "DATE",
  BOOLEAN: "BOOLEAN",
  TEXT: "TEXT",
  DESCRIPTION: "DESCRIPTION",
  NAME: "NAME",
  URL: "URL",
  FALSE: "false",
  TRUE: "true",
};

export const S3_URL_EXPIRATION = 39600;
export const DIRECTION_IN = "IN";
export const INITIATION_METHODS = {
  ABANDONED_AGENT: "ABANDONED_AGENT",
  REJECTED: "REJECTED",
  MISSED: "MISSED",
  FAILED: "FAILED",
};

export const DATA_TYPES = {
  STRING: "string",
};
export const REGISTERED_USER_EMBED_URLS = "RegisteredUser";
export const ANONYMOUS_USER_EMBED_URLS = "AnonymousUser";

export const AGENT_TO_AGENT_CHIME_CALL = {
  CONNECTING: "connecting",
  CONNECTED: "connected",
  HANGUPBYINITIATOR: "hangup by initiator",
  HANGUPBYRECEIVER: "hangup by receiver",
  CALLENDED: "call ended",
  CALLREJECTED: "rejected",
  CALLMISSED: "missed",
};

export const API_METHODS = {
  GET: "Get",
  DELETE: "Delete",
  LIST: "List",
};

export const CONTACT_FLOWS = {
  TRANSFER_TO_AGENT_BY_ID: "Transfer-to-agent-by-id",
  DEFAULT_TASKS_FLOW: "dextr-tasks-flow",
};

export const CHIME_AGENT_TO_AGENT_DEFIITION_ID = "ac23eee3-132f-4857-8ea5-8424bd4d0556";

export const TYPES = {
  OBEJCT: "object",
  STRING: "string",
};

export const CREATE_CONNECT_BACKUP = {
  CONNECT_BACKUP_VERSION: "dextr/createConnectBackupVersion/createConnectBackupVersion",
  USERS: "dextr/createConnectBackupVersion/createConnectBackupVersion_Users",
  QUEUES: "dextr/createConnectBackupVersion/createConnectBackupVersion_Queues",
  ROUTING_PROFILES: "dextr/createConnectBackupVersion/createConnectBackupVersion_RPs",
  ROUTING_PROFILE_QUEUES: "dextr/createConnectBackupVersion/createConnectBackupVersion_RpQueues",
};

export const DISABLED = "disabled";
export const PRESENTED_NOT_ANSWERED_STATUS = { WrapUp: "WrapUp", Custom: "Custom", ForcedRelease: "ForcedRelease" };
export const CHAT = "chat";
export const CONNECTED = "connected";
